<template>
  <v-btn
    :loading="loading"
    :variant="variant"
    :class="variant === 'text' ? 'text-textButton pa-0' : ''"
    :aria-label="text"
    @click.prevent="lead(props.downloadNick, props.softwareTitle)"
  >
    <slot name="prepend" />
    <slot>{{ text }}</slot>
    <slot name="append" />
  </v-btn>
</template>
<script setup>
import { useLead } from "@/composables/useLead";
const props = defineProps({
  text: { type: String, required: false, default: "Play Now" },
  downloadNick: { type: String, default: "" },
  softwareTitle: { type: String, default: "" },
  variant: { type: String, default: "elevated" },
});

const loading = ref(false);

const page = inject("page");
const { lead, loadingLead } = useLead(page);

watchEffect(() => {
  loading.value = loadingLead().value;
});
</script>
